


































































































































































































import { CompanyContact } from '@/api/interfaces/companyContact'
import { newCompanyContact } from '@/components/molecules/select-search/store/companyContact'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'

const IRI_EN_COURS_CREATION = 'en_cours_creation'

export default defineComponent({
  components: {
    PhoneNumber: () => import('@/components/molecules/input/phone-number.vue'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    company: {
      type: Object,
      required: false,
    },
    companyContactEdit: {
      type: Object,
      required: false,
    },
    showOnlyManager: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      isSubmiting: false,
      companyContact: newCompanyContact() as CompanyContact,
    }
  },
  computed: {
    ...mapGetters('profession', {
      getProfessionGestionnaire: 'getProfessionGestionnaire',
    }),
    ...mapState('profession', {
      listProfessions: 'list',
    }),
    companyName (): string {
      return this.company ? (this.company.name as string) : ''
    },
    isEditingCompanyContact (): boolean {
      return (this.companyContact['@id'] !== undefined && this.companyContact['@id'] !== '')
    },
  },
  created () {
    if (this.companyContactEdit && this.companyContactEdit['@id']) {
      (this.companyContact as CompanyContact) = this.companyContactEdit as CompanyContact
    } else {
      this.companyContact = newCompanyContact()
    }
  },
  watch: {
    companyContactEdit: {
      handler (newVal) {
        if (newVal && newVal['@id']) {
          (this.companyContact as CompanyContact) = { ...this.companyContactEdit as CompanyContact }
        } else {
          this.companyContact = newVal
        }
      },
    },
  },
  methods: {
    ...mapActions('companyContactList', {
      post: 'post',
      put: 'put',
    }),
    async onSubmit () {
      this.isSubmiting = true
      try {
        if (this.companyContact['@id'] && this.companyContact['@id'] !== IRI_EN_COURS_CREATION) {
          // case update companyContact when edit company
          await this.put(this.companyContact)
          this.$emit('companyContactUpdated', this.companyContact, this.companyContactEdit)
        } else {
          if (this.company && this.company['@id']) {
            // case create companyContact when edit company
            this.companyContact.company = this.company['@id']
            this.companyContact = await this.post(this.companyContact)
          }
          if (this.companyContact['@id'] !== IRI_EN_COURS_CREATION) {
            // case create when create company
            if (!this.companyContact['@id']) {
              this.companyContact['@id'] = IRI_EN_COURS_CREATION
            }
            this.$emit('companyContactCreated', this.companyContact)
          } else {
            // case edit when create company
            this.$emit('companyContactUpdated', this.companyContact, this.companyContactEdit)
          }
        }
      } finally {
        this.clear()
        this.$emit('close')
      }
    },
    clear () {
      this.companyContact = newCompanyContact()
      this.isSubmiting = false
      const observer = this.$refs.observer as InstanceType<
      typeof ValidationObserver
    >
      observer.reset()
    },
  },
})
